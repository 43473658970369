<template>
  <div class="page">
    <div class="title">月考勤管理</div>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <div class="custom_button plain" @click="chooseExcel">按月导入考勤</div>
        <input
          ref="fileInput"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          @change="importRoster"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
        <div class="custom_button plain no_select" @click="exportinfo">
          导出
        </div>
        <div class="custom_button plain no_select" @click="downloadTamplate">
          下载导入模板
        </div>
      </div>
    </div>
    <div class="fil_left">
      <el-form label-width="80px" :inline="true">
        <el-form-item label="文件名称">
          <el-input
            v-model="localName"
            placeholder="请输入上传文件的本地名字"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="status" placeholder="请选择审核状态" clearable>
            <!-- <el-option label="请选择审核状态" value=""></el-option> -->
            <el-option label="待审核" value="0"></el-option>
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="审核拒绝" value="2"></el-option>
            <el-option label="已计薪" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="录入开始结束时间" label-width="130px">
          <el-date-picker
            v-model="filterTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="20px" style="margin-left: 30px">
          <el-button type="primary" @click="filterTable">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- table -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
      @selection-change="handleCurrentChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="attendance_time"
        label="统计时间"
      ></el-table-column>
      <el-table-column prop="employee_num" label="包含员工数"></el-table-column>
      <el-table-column prop="create_time" label="录入时间"></el-table-column>
      <el-table-column prop="name" label="录入人"></el-table-column>
      <el-table-column
        prop="status_value"
        label="考勤审核状态"
      ></el-table-column>
      <el-table-column prop="local_name" label="文件名称"></el-table-column>
      <el-table-column prop="audit_time" label="审核时间"></el-table-column>
      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot:default="scope">
          <div style="display: flex; justify-content: center">
            <span class="s_operation" @click="checkdetail(scope.row.id)"
              >明细</span
            >
            <span class="s_operation" @click="delMessage(scope.row.id)"
              >删除</span
            >
            <span
              class="s_operation"
              v-if="scope.row.status == 0"
              @click="showexamine(scope.row.id)"
              >审核</span
            >
            <span
              class="s_operation"
              v-if="scope.row.status == 1"
              @click="calculation(scope.row.id)"
              >计薪</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- @current-change="getattendancedetail" -->
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.limit"
      :current-page.sync="page.page"
      @current-change="getCurrent"
    >
    </el-pagination>

    <el-dialog title="月考勤明细" :visible.sync="dialogVisible" width="80%">
      <el-table
        :data="attendancedetail"
        :header-cell-style="tableHeaderStyle"
        :row-class-name="tableRowClassName"
        :cell-style="tableCellStyle"
        style="width: 100%"
      >
        <el-table-column prop="staff_id" label="员工id"></el-table-column>
        <el-table-column prop="real_name" label="姓名"></el-table-column>
        <el-table-column prop="station_name" label="岗位名称"></el-table-column>
        <el-table-column
          prop="should_attendance"
          label="应出勤天数"
        ></el-table-column>
        <el-table-column
          prop="actual_attendance"
          label="实际出勤天数"
        ></el-table-column>
        <el-table-column
          prop="legal_overtime"
          label="法定加班"
        ></el-table-column>
        <el-table-column
          prop="week_overtime"
          label="周末加班"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_type"
          label="加点替岗类型	"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_first"
          label="加点替岗1"
        ></el-table-column>
        <el-table-column
          prop="add_replace_wages_standard_second"
          label="加点替岗2"
        ></el-table-column>
        <!-- <el-table-column prop="add_replace_wages_standard_third" label="加点替岗3"></el-table-column> -->
        <el-table-column prop="change_off" label="换休"></el-table-column>
        <el-table-column prop="paid_vacation" label="有薪假	"></el-table-column>
        <el-table-column prop="stay_away" label="事假"></el-table-column>
        <el-table-column prop="without" label="旷工"></el-table-column>
        <el-table-column prop="other" label="婚丧产护假"></el-table-column>
        <el-table-column prop="sick_leave" label="病假"></el-table-column>
        <el-table-column prop="injury_leave" label="工伤假"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column
          prop="attendance_time"
          label="考勤时间	"
        ></el-table-column>
        <el-table-column prop="create_time" label="录入时间	"></el-table-column>
        <el-table-column prop="update_time" label="更新时间	"></el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :total="page1.total"
        :page-size="page1.limit"
        :current-page.sync="page1.page"
        @current-change="getDetailList"
      >
      </el-pagination>
    </el-dialog>

    <el-dialog title="考勤审核" :visible.sync="dialogVisible1" width="20%">
      <div style="margin-bottom: 20px">
        <el-radio v-model="form.status" label="1">通过</el-radio>
        <el-radio v-model="form.status" label="2">不通过</el-radio>
      </div>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-if="form.status == 2"
        v-model="form.remark"
      >
      </el-input>
      <div class="button_box">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="examine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      search: {
        keyword: "",
      },
      tableData: [],
      page: {
        limit: 10,
        page: 1,
        total: 0,
      },
      page1: {
        limit: 10,
        page: 1,
        total: 0,
        currentId: 0,
      },
      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
      dialogVisible: false,
      dialogVisible1: false,
      attendancedetail: [],
      form: {
        id: "",
        status: "1",
        remark: "",
      },

      //搜索过滤 状态  文件名称 开始时间 结束时间
      // filtFrom: {
      localName: "",
      status: "",
      filterTime: "",
      multipleSelection: [],
      // },
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("employee", ["getEmployeeTemplate"]),
    ...mapActions("pay", [
      "loadattendance",
      "getattendancedetail",
      "getexportinfo",
      "postattendance",
      "attendanceexamine",
      "calculationSalary",
      "delDepartment",
    ]),
    // 搜索
    filterTable() {
      let loading = this.$loading();
      this.page.page = 1;
      let params = {
        page: this.page.page,
        size: this.page.limit,
      };
      if (this.localName != "") {
        params.local_name = this.localName;
      }
      if (this.status != "") {
        params.status = this.status;
      }
      // console.log(this.filterTime);
      if (this.filterTime != "" && this.filterTime != null) {
        let start_time = Math.floor(
          new Date(this.filterTime[0]).getTime() / 1000
        );
        let end_time = Math.floor(
          new Date(this.filterTime[1]).getTime() / 1000
        );
        console.log(start_time, end_time);
        params.start_time = start_time;
        params.end_time = end_time;
      }
      console.log(params);
      this.loadattendance(params)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
          this.$nextTick(() => {
            loading.close();
          });
        })
        .catch((e) => {
          this.$message.error(e.msg);
          loading.close();
        });
    },
    //分页
    getCurrent(e) {
      let loading = this.$loading();
      console.log("分页", e);
      let params = {
        page: e,
        size: this.page.limit,
      };
      if (this.localName != "") {
        params.local_name = this.localName;
      }
      if (this.status != "") {
        params.status = this.status;
      }
      // console.log(this.filterTime);
      if (this.filterTime != "" && this.filterTime != null) {
        let start_time = Math.floor(
          new Date(this.filterTime[0]).getTime() / 1000
        );
        let end_time = Math.floor(
          new Date(this.filterTime[1]).getTime() / 1000
        );
        console.log(start_time, end_time);
        params.start_time = start_time;
        params.end_time = end_time;
      }
      this.loadattendance(params)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data.list;
          this.page.total = res.data.data.total;
          this.$nextTick(() => {
            loading.close();
          });
        })
        .catch((e) => {
          this.$message.error(e.msg);
          loading.close();
        });
    },

    /**
     * 下载导入模板
     */
    downloadTamplate() {
      let loading = this.$loading();
      var name = "salary";
      this.getEmployeeTemplate(name)
        .then((res) => {
          console.log(res);
          loading.close();
          let filename =
            res.headers["content-disposition"].match(/filename=(.*)/)[1];
          filename = filename.replace(/^(\s|")+|(\s|")+$/g, "");
          // 将二进制流转为blob
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
            window.navigator.msSaveBlob(blob, decodeURI(filename));
          } else {
            // 创建新的URL并指向File对象或者Blob对象的地址
            const blobURL = window.URL.createObjectURL(blob);
            // 创建a标签，用于跳转至下载链接
            const tempLink = document.createElement("a");
            tempLink.style.display = "none";
            tempLink.href = blobURL;
            tempLink.setAttribute("download", decodeURI(filename));
            // 兼容：某些浏览器不支持HTML5的download属性
            if (typeof tempLink.download === "undefined") {
              tempLink.setAttribute("target", "_blank");
            }
            // 挂载a标签
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            // 释放blob URL地址
            window.URL.revokeObjectURL(blobURL);
          }
        })
        .catch((e) => {
          loading.close();
          console.error(e);
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
        });
    },

    loadDataFactory() {
      let params = {};
      return () => {
        params.page = this.page.page;
        params.size = this.page.limit;
        let loading = this.$loading();
        this.loadattendance(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },
    checkdetail(id) {
      let loading = this.$loading();
      this.page1.currentId = id;
      let params = {};
      params.id = id;
      params.page = this.page1.page;
      params.limit = this.page1.limit;
      this.getattendancedetail(params)
        .then((res) => {
          console.log(res, "分页");
          this.attendancedetail = res.data.data.list;
          this.page1.total = res.data.data.total;
          this.dialogVisible = true;
          loading.close();
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    // 删除信息接口
    delMessage(row) {
      console.log("row", row);
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delDepartment({
          id: row,
        })
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message({
              type: "error",
              message: e.msg,
            });
            this.loadData();
          });
      });
    },
    getDetailList(e) {
      let loading = this.$loading();
      let params = {};
      params.page = e;
      params.limit = this.page1.limit;
      params.id = this.page1.currentId;
      this.getattendancedetail(params)
        .then((res) => {
          console.log(res, "分页");
          this.attendancedetail = res.data.data.list;
          this.page1.total = res.data.data.total;
          this.dialogVisible = true;
          loading.close();
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    tableRowClassName({ row }) {
      if (row.is_red == 1) {
        return "warm-row";
      }
      return "";
    },
    //导出数据表
    exportinfo() {
      if (!this.$refs.multipleTable.store.states.selection[0]) {
        this.$message.error("请选中要导出的数据");
        return;
      }
      let params = {};
      //单选
      let selectedItems = this.$refs.multipleTable.store.states.selection;
      params.id = selectedItems[0].id;

      params.type = 0;
      params.name = "考勤信息.xlsx";

      //全选
      // let idList = [];
      // this.multipleSelection.map((item) => {
      //   idList.push(item.id);
      //   params.id = idList.join(",");
      // });
      // console.log(params, "cnahus1");

      this.getexportinfo(params);
    },

    handleCurrentChange(val) {
      console.log("选择表单", val);
      //全选
      // this.multipleSelection = val;
      // 一次只能选中一条数据
      this.currentRow = val;
      this.tableData.forEach((item) => {
        if (val[val.length - 1] === item) {
          this.$refs.multipleTable.toggleRowSelection(item, true);
        } else {
          this.$refs.multipleTable.toggleRowSelection(item, false);
        }
        // this.$refs.multipleTable.toggleRowSelection(item, true);
      });
    },
    /**
     * 选择excel
     */
    chooseExcel() {
      this.$refs.fileInput.click();
    },

    /**
     * 导入考勤表
     */
    async importRoster(file) {
      console.log(this.$store.getters);
      let loading = this.$loading();
      try {
        // let link = (
        await this.uploadFile({
          file: file.target.files[0],
          type: "attendance_xls",
        }).then((rm) => {
          let link = rm.data.data;
          console.log(rm, "返回");

          this.postattendance(link)
            .then((res) => {
              console.log(res, "resdata");
              let error_remark = res.data.data.error_remark;
              loading.close();
              if (error_remark.length > 0) {
                this.$alert(error_remark.join(), "错误", {
                  confirmButtonText: "确定",
                  type: "error",
                });
              } else {
                this.$alert(res.data.msg, "成功", {
                  confirmButtonText: "确定",
                  type: "success",
                });
              }
              // this.$message.success(res.data.msg);
              this.loadData();
            })
            .catch((e) => {
              loading.close();
              console.error(e);
              // this.$message.error({
              //   message: e.msg,
              //   showClose: true,
              // });
              this.$alert(e.msg, "错误", {
                confirmButtonText: "确定",
                type: "error",
              });
            });
        });
      } catch (e) {
        loading.close();
        console.error(e);
        this.$message.error({
          message: e.msg,
          showClose: true,
        });
      }
    },
    // 考勤审核
    showexamine(id) {
      this.dialogVisible1 = true;
      this.form.id = id;
    },
    examine() {
      let params = this.form;
      if (params.status == 1) {
        params.remark = "";
      }
      this.attendanceexamine(params)
        .then((res) => {
          console.log(res);
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.dialogVisible1 = false;
        })
        .then(() => {
          this.loadData();
        })
        .catch((e) => {
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
        });
    },
    calculation(id) {
      let params = {};
      params.id = id;
      this.calculationSalary(params)
        .then((res) => {
          console.log(res);
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.loadData();
        })
        .catch((e) => {
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
          this.loadData();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fil_left {
  ::v-deep .el-form-item {
    // float: left;
  }
  ::v-deep .el-col-2 {
    text-align: center;
  }
}
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;
    margin-left: auto;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.s_operation {
  color: #4db9d5;
  margin-right: 5px;
}

// 移入鼠标改变指针样式
.s_operation :hover {
  cursor: pointer;
}

.button_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
